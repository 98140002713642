//created by Peggy on 2021/4/12
import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  Breadcrumb,
  Button,
  Divider,
  Form,
  Input,
  message,
  Select,
} from "antd";
import { stateList } from "@/types/enum";
import StripePayCom from "@/components/StripePayment";

import Info from "@/components/Modal/Info";
import icon_donation from "@/images/icons/icon_donation.svg";

import { getBillInfo } from "@/api/donation-api";
import { couponCheck } from "@/api/consultation-api";

import "@/styles/dashboard/grant.less";
import styled from "styled-components";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import IsThisCorrect from "@/pages/homepage/donation/IsThisIncorrect";
import { user } from "@/model/user";
const { getData: getCountryList } = require('country-list');
const countryList = getCountryList()
const PaymentContainer = styled.div``;

const StripePay = React.forwardRef<any, any>((props, ref) => (
  <StripePayCom {...props} submitRef={ref} />
));

const Payment = () => {
  const { orderId, fee, name: grantName } = useParams() as any;
  const history = useHistory();
  const submitRef = useRef<any>(null);
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false);
  const {store:userStore} = useModel(user);
  const [pay, setPay] = useState<any>({
    payment: "credit card",
    address: "",
    email: "",
    name: "",
    city: "",
    state: "",
    country: "US",
    code: "",
    coupon: ""
  });
  const { store: { isMobile } } = useModel(view)
  const onFinish = async (values: any) => {
    // Discussed with Will about these codes. It was removed caused because of a change in payment flow business.
    // Old logic: Payment immediately when artist apply a request
    // New logic: Payment when Admin accept artist's application

    // grantByStripe({ orderId, coupon: promo.name }).then(async (data: any) => {
    //   setSecretId(data?.client_secret);
    //   await setPay({ ...pay, ...values });
    //   await submitRef?.current?.submit();
    // });
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },1500);
    await setPay({ ...pay, ...values, coupon:promo.name });
    await submitRef?.current?.submit();
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };

  //coupons
  const [promo, setPromo] = useState<any>({
    name: "",
    discount: 0,
    error: false,
  });
  const couponValidate = (name: string) => {
    if (!name) {
      setPromo({ name, discount: 0 });
      return false;
    }
    couponCheck({ name, productType: "rush_fee", price: fee })
      .then((data) =>
        setPromo({ name, discount: data?.discount, error: false })
      )
      .catch((e) => {
        setPromo({
          name: "",
          discount: 0,
          error: true,
        });
      });
  };

  //info
  const [show, setShow] = useState(false);

  useEffect(() => {
    getBillInfo({}, true).then((data) => {
      const {
        billing_details: {
          address: { city, line1: address, postal_code: code, state, country },
          ...others
        },
      } = (Object.values(data)?.length && data) || {
        billing_details: {
          address: {
            city: "",
            line1: "",
            postal_code: "",
            state: "",
            country: "US",
          },
        },
      };
      let _pay = {
        ...others,
        city,
        address,
        code,
        state,
        country: country || 'US',
        name:(userStore.info.firstName + ' ' + userStore.info.lastName).trim(),
        email:userStore.info.email
      };
      setPay(_pay);
      form.setFieldsValue(_pay);
    });
  }, []);

  return (
    <PaymentContainer className="grant-payment-container common-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/dashboard/grant">
          Grant applications
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/dashboard/grant/create">
          Request grant review
        </Breadcrumb.Item>
        <Breadcrumb.Item>Grant expidation payment</Breadcrumb.Item>
      </Breadcrumb>
      <h1>Grant expidation payment</h1>
      <div className={isMobile ? '' : "flex start align-start"}>
        <div className="card-wrap">
          <h2>Payment information</h2>
          <h4>Credit card information</h4>
          <StripePay
            payId={+orderId}
            payInfo={pay}
            ref={submitRef}
            payType="grant_rush"
            onNext={() => {
              setShow(true);
            }}
          />
          <Divider />
          <h4>Billing information </h4>
          <IsThisCorrect isStep2/>
          <Form
            layout="vertical"
            size="large"
            name="basic"
            form={form}
            initialValues={pay}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={(val)=>{
              if (val.country) {
                form.setFieldsValue({ state: '' })
              }
            }}
          >
            <Form.Item
              label="Full Name"
              name="name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter full Name",
                },
              ]}
            >
              <Input disabled={!!userStore.token} size="large" placeholder="Enter full Name" />
            </Form.Item>
            <Form.Item
              label="Email address"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Enter Email address",
                },
              ]}
            >
              <Input disabled={!!userStore.token} size="large" placeholder="Enter Email address" />
            </Form.Item>
            <Form.Item
              label="Billing Address"
              name="address"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  message: "Enter billing address",
                },
              ]}
            >
              <Input maxLength={30} size="large" placeholder="Address" />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter city",
                },
              ]}
            >
              <Input size="large" placeholder="City" />
            </Form.Item>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Choose Country",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Country"
              >
                {Object.values(countryList)?.map((item: any) => (
                  <Select.Option value={item.code} key={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="flex start align-start row-flex">
              <Form.Item noStyle dependencies={['country']}>
                {
                  ({ getFieldValue }) => {
                    return getFieldValue('country') === 'US' ?
                      <Form.Item
                        label="State"
                        name="state"
                        className="select-item"
                        rules={[
                          {
                            required: true,
                            whitespace: false,
                            message: "Select State",
                          },
                        ]}
                      >

                        <Select
                          showSearch
                          size="large"
                          placeholder="State"
                        >
                          {Object.values(stateList)?.map((item) => (
                            <Select.Option value={item} key={item}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item> :
                      <Form.Item
                        label="State"
                        name="state"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Enter State",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="State" />
                      </Form.Item>
                  }
                }
              </Form.Item>
              <Form.Item
                label="Zip code"
                name="code"
                rules={[
                  {
                    required: false,
                    whitespace: true,
                    message: "Enter zip code",
                  },
                ]}
              >
                <Input size="large" placeholder="Zip code" maxLength={6} />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="card-wrap">
          <h2>Order summary</h2>
          <p className="flex">
            <span>Grant</span>
            {decodeURIComponent(grantName)}
          </p>
          <p className="flex">
            <span>Expidation fee</span>
            {Number(fee || 0).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            })}
          </p>
          <Divider />
          <p className="flex">
            Discount
            <b>
              {Number(promo?.discount || 0).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </b>
          </p>
          <p className="flex">
            Total amount
            <b>
              {Number(
                fee - promo?.discount >= 0 ? fee - promo?.discount : 0
              ).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </b>
          </p>
          <Divider />
          <div className="flex align-start" style={{ width: "100%" }}>
            <div style={{ flex: "0 0 calc(100% - 120px)" }}>
              <div
                className={`field-form-items ${promo?.error ? "field-error-item" : ""
                  }`}
              >
                <span>PROMO CODE</span>
                <Input
                  allowClear
                  placeholder="Promo code..."
                  onChange={(e) =>
                    setPromo({
                      ...promo,
                      name: e?.target.value,
                      error: false,
                    })
                  }
                />
              </div>
              <p className="error-text">Invalid code, please try another one</p>
            </div>
            <Button
              style={{ flex: "0 0 100px", height: "56px", marginLeft: 20 }}
              onClick={() => couponValidate(promo.name)}
            >
              Apply
            </Button>
          </div>
          <Divider />
          <p className="tips">
            By confirming this payment, you agree to our{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://thefield.org/terms-of-service/"
            >
              TERMS OF SERVICE
            </a>
          </p>

          <Button type="primary" block loading={loading} onClick={() => form.submit()}>
            confirm payment
          </Button>
        </div>
      </div>
      <Info
        className="event-info-modal"
        visible={show}
        width={660}
        footer={[
          <Button onClick={() => history.push("/dashboard/message")}>
            contact us
          </Button>,
          <Button
            onClick={() => history.push("/dashboard/grant")}
            type="primary"
          >
            Got it
          </Button>,
        ]}
        img={icon_donation}
      >
        <h1>Your grant review request has been received!</h1>
        <p>
          We have received your application and will confirm your eligibility.
          Once the review is completed, you will receive an email with next
          steps.
        </p>
      </Info>
    </PaymentContainer>
  );
};

export default Payment;
